import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import dayjs, { Dayjs } from 'dayjs';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { size, trim } from 'lodash';

import DatePicker from 'elements/DatePicker';
import TextField from 'elements/Form/TextField';
import Button from 'elements/Button';
import { HOC } from 'elements/Modal';
import DropdownField from 'elements/DropdownField';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import { ButtonWrapper } from 'pages/Dashboard/components/UpdateSpeechHistory/style';
import CheckboxesAutoComplete from 'elements/AutoComplete/CheckboxesAutoComplete';
import { useIsSpeechCraftMFE, useValidations } from 'hooks';
import { useAppContext } from 'contexts/AppContext';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';
import { getUserName } from 'utils/utility';

import {
  useGetClubsQuery,
  useGetClubMembersQuery,
} from 'pages/Dashboard/components/Feedback/feedbackApi';
import { getValidationSchema } from './validationSchema';

const initialValues = {
  clubId: '',
  clubMembers: [],
  speechTitle: '',
  speechDate: '',
  request: '',
  evaluatorName: '',
};

const ShareFormModal: FC<any> = ({
  handleSubmitForm,
  isDirectFeedback,
  handleClose,
}) => {
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const user = getAuthenticatedUser();
  const validationSchema: any = getValidationSchema(
    isDirectFeedback,
    isSpeechCraftMFE,
  );

  const [clubId, setClubId] = useState('');

  const _initialValues = {
    ...initialValues,
    ...(isDirectFeedback && { clubMembers: '' }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: _initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      const clubMember = clubMembers.find(
        (cm: any) => cm.id === values.clubMembers,
      );

      const selectedClub = clubData.find(
        (club: any) => club.id === values.clubId,
      );

      const eventMember =
        eventMembersData?.attendees?.find(
          (em: any) => em.id === values.clubMembers,
        ) || '';

      const updatedValues = {
        ...values,
        evaluatorName: isSpeechCraftMFE
          ? getUserName(eventMember)
          : clubMember?.name,
      };
      const feedbackFormData = {
        ...values,
        member: isSpeechCraftMFE
          ? { name: getUserName(eventMember), id: eventMember?.id }
          : clubMember,
        evaluator: user,
        club: selectedClub,
      };

      handleSubmitForm(
        isDirectFeedback,
        feedbackFormData,
        updatedValues,
        setSubmitting,
      );
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    dirty,
    setFieldTouched,
    touched,
    errors,
    isValid,
    isSubmitting,
  }: any = formik;

  const { data: clubData = [], isFetching: isFetchingClubs } = useGetClubsQuery(
    {},
  );
  const {
    data: clubMembers = [],
    isFetching: isFetchingMembers,
    isSuccess: isSuccessMembers,
  } = useGetClubMembersQuery(
    { clubId },
    { skip: clubId === '' || isSpeechCraftMFE },
  );

  const {
    data: eventMembersData,
    isFetching: isFetchingEventMembers,
    isSuccess: isSuccessEventMembers,
  } = useGetEventAttendeesQuery(
    { attendeesType: 'all' },
    { skip: !isSpeechCraftMFE },
  );

  const clubOptions = clubData.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        label: name,
        value: id,
      },
    ],
    [],
  );

  const clubMembersOptions = (
    isSpeechCraftMFE ? eventMembersData?.attendees || [] : clubMembers
  ).reduce((acc: any, member: any) => {
    if (member.id === user.userId) {
      return acc;
    }

    return [
      ...acc,
      {
        [isDirectFeedback ? 'label' : 'name']: getUserName(member),
        [isDirectFeedback ? 'value' : 'id']: member.id,
      },
    ];
  }, []);

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;
    const isRequestField = name === 'request';
    const updatedValue = size(trim(value)) > 0 ? value : '';

    setFieldValue(name, isRequestField ? updatedValue : value);
    setFieldTouched(name, true, false);

    if (name === 'clubId') {
      setClubId(value);

      setFieldValue('clubMembers', isDirectFeedback ? '' : [], false);
      setFieldTouched(name, false, false);
    }
  };

  const handleChangeDate = (newValue: Dayjs | null) => {
    setFieldValue('speechDate', dayjs(newValue).format('YYYY-MM-DD'));
    setFieldTouched('speechDate', true, false);
  };

  useValidations(formik);

  return (
    <>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <FormControlLabel
            label='Speech Title'
            labelPlacement='top'
            control={
              <TextField
                placeholder={
                  isDirectFeedback
                    ? "Enter the speaker's speech title."
                    : 'Enter speech title'
                }
                name='speechTitle'
                value={formikValues.speechTitle}
                className={`${formikValues.speechTitle ? 'has-data' : ''}`}
                helperText={touched['speechTitle'] && errors['speechTitle']}
                error={!!touched['speechTitle'] && !!errors['speechTitle']}
                onChange={handleUpdateField}
              />
            }
          />
        </Grid>
        {!isSpeechCraftMFE && (
          <Grid item xs={12}>
            <FormControlLabel
              label='Club'
              labelPlacement='top'
              control={
                <DropdownField
                  value={formikValues.clubId}
                  options={clubOptions}
                  handleChange={handleUpdateField}
                  name='clubId'
                  className={`${formikValues.club ? 'has-data' : ''}`}
                  helperText={touched['clubId'] && errors['clubId']}
                  error={!!touched['clubId'] && !!errors['clubId']}
                  isLoading={isFetchingClubs}
                  placeholder='Select a club'
                />
              }
            />
          </Grid>
        )}
        {isSpeechCraftMFE && (
          <Grid item xs={12}>
            <FormControlLabel
              label='Event'
              labelPlacement='top'
              control={
                <TextField
                  name='event'
                  value={event?.name}
                  className={`${formikValues.request ? 'has-data' : ''}`}
                  disabled={true}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            label={isSpeechCraftMFE ? 'Speechcrafter' : 'Speaker'}
            labelPlacement='top'
            control={
              isDirectFeedback ? (
                <>
                  <DropdownField
                    value={formikValues.clubMembers}
                    options={clubMembersOptions}
                    handleChange={handleUpdateField}
                    name='clubMembers'
                    className={`${formikValues.clubMembers ? 'has-data' : ''}`}
                    helperText={touched['clubMembers'] && errors['clubMembers']}
                    error={!!touched['clubMembers'] && !!errors['clubMembers']}
                    isLoading={isFetchingMembers || isFetchingEventMembers}
                    placeholder={`Select ${
                      isSpeechCraftMFE ? 'speechcrafter' : 'member'
                    }`}
                  />
                </>
              ) : (
                <CheckboxesAutoComplete
                  multiple
                  id='select-club-members'
                  options={clubMembersOptions}
                  disableCloseOnSelect
                  loading={isFetchingMembers || isFetchingEventMembers}
                  loadingText={`Loading ${
                    isSpeechCraftMFE ? 'Speechcrafters' : 'Members'
                  }`}
                  getOptionLabel={(option: any) => option.name}
                  defaultValue={formikValues.clubMembers}
                  selectedOptions={(members: any) => {
                    setFieldValue('clubMembers', members);
                    setFieldTouched('clubMembers', true, false);
                  }}
                  placeholder={`Select ${
                    isSpeechCraftMFE ? 'Speechcrafter' : 'member'
                  }(s)`}
                  name='clubMembers'
                  helperText={touched['clubMembers'] && errors['clubMembers']}
                  error={!!touched['clubMembers'] && !!errors['clubMembers']}
                />
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label='Date'
            labelPlacement='top'
            control={
              <DatePicker
                label='Date'
                value={formikValues.speechDate}
                handleChange={handleChangeDate}
                name='speechDate'
                className={`primary-color-icon ${
                  formikValues.speechDate ? 'has-data' : ''
                }`}
                helperText={touched['speechDate'] && errors['speechDate']}
                error={!!touched['speechDate'] && !!errors['speechDate']}
                placeholder='Select speech date'
              />
            }
          />
        </Grid>
        {!isDirectFeedback && (
          <Grid item xs={12}>
            <FormControlLabel
              label='Request Description'
              labelPlacement='top'
              control={
                <TextField
                  multiline
                  name='request'
                  value={formikValues.request}
                  className={`${formikValues.request ? 'has-data' : ''}`}
                  helperText={touched['request'] && errors['request']}
                  error={!!touched['request'] && !!errors['request']}
                  onChange={handleUpdateField}
                  maxRows={1}
                  placeholder='Example: Please provide feedback on my recent speech.'
                />
              }
            />
          </Grid>
        )}
      </Grid>
      <ButtonWrapper>
        <Button variant='outlined' onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => formik.handleSubmit()}
          // disabled={!dirty || !isValid || isSubmitting}
        >
          {isDirectFeedback ? 'Continue' : 'Send'}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default HOC(ShareFormModal);
