import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useMemo, useState, useEffect } from 'react';

import Loader from 'elements/Loader';
import {
  SpeechCraftContainer,
  StyledAvatar,
  StyledCell,
} from 'pages/SpeechValidation/style';
import { useGetEventAttendeesCertificatesQuery } from 'api/speechCertificatesApi';
import dummyImage from 'assets/images/dummyImage.png';
import { Role } from 'config';

const columns: GridColDef[] = [
  {
    field: 'userName',
    headerName: 'Recipient',
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <StyledCell gap={4}>
          <StyledAvatar
            src={params.row.userImage || dummyImage}
            alt={params.row.userName}
          />
          <Typography variant='h4'>{params.row.userName}</Typography>
        </StyledCell>
      );
    },
    renderHeader: () => <Typography variant='h5'>Recipient</Typography>,
  },
  {
    field: 'isCoordinator',
    headerName: 'Role',
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderHeader: () => <Typography variant='h5'>Role</Typography>,
    renderCell: (params: any) => {
      return (
        <Typography variant='h4'>
          {params.row.isCoordinator ? 'Coordinator' : 'Speechcrafter'}
        </Typography>
      );
    },
  },
  {
    field: 'isCertificateAwarded',
    flex: 1,
    minWidth: 170,
    sortable: false,
    renderHeader: () => <></>,
    renderCell: (params: any) => {
      return (
        <Typography className='secondary-color' variant='h4'>
          {params.row.isCertificateAwarded ? 'Certificate Awarded' : ''}
        </Typography>
      );
    },
  },
];

const QuickSearchToolbar = () => (
  <Box
    sx={{
      p: 0.5,
      pb: 0,
    }}
  >
    <GridToolbarQuickFilter
      quickFilterParser={(searchInput: string) =>
        searchInput
          .split(',')
          .map(value => value.trim())
          .filter(value => value !== '')
      }
    />
  </Box>
);

const NoRowsOverlay = () => (
  <Stack alignItems='center' justifyContent='center' height='100%'>
    <Typography variant='h3'>No data found!</Typography>
  </Stack>
);

const Recipients = () => {
  const { values, setFieldValue, errors } = useFormikContext<any>();

  const [selectionModel, setSelectionModel] = useState<any>([]);

  const {
    data: eventAttendees = [],
    isLoading,
    isSuccess,
  }: any = useGetEventAttendeesCertificatesQuery({});

  const rows = useMemo(
    () =>
      eventAttendees.map((row: any) => {
        let isCoordinator = false;
        for (const role of row.roles) {
          if (role.positionCode === Role.coordinator) {
            isCoordinator = true;
            break;
          }
        }

        return {
          id: row.id,
          userId: row.user.id,
          userName: row.user.name,
          userImage: row?.user?.memberPhotoUrl || row.user.profileImage.medium,
          isCertificateAwarded: row.certificateAwarded,
          isCoordinator: isCoordinator,
        };
      }),
    [eventAttendees],
  );

  const handleOnChangeSelectionModel = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);

    const selectedAttendeesRows = rows.filter((row: any) =>
      newSelectionModel.includes(row.id),
    );

    setFieldValue('attendees', selectedAttendeesRows);
  };

  useEffect(() => {
    // set selected rows, if coming back from third step
    // only run on 1st render
    const { attendees = [] } = values;
    const selections = [];

    for (const item of attendees) {
      selections.push(item.id);
    }

    setSelectionModel(selections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpeechCraftContainer>
      {isLoading ? <Loader /> : null}

      {isSuccess ? (
        <Box height={450}>
          <DataGrid
            rowHeight={76}
            hideFooter={true}
            checkboxSelection
            disableColumnMenu
            slots={{
              toolbar: QuickSearchToolbar,
              noRowsOverlay: NoRowsOverlay,
            }}
            columns={columns}
            rows={rows}
            isRowSelectable={(params: any) => !params.row.isCertificateAwarded}
            getRowClassName={(params: any) =>
              classNames({
                'disable-selected-row': params.row.isCertificateAwarded,
              })
            }
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleOnChangeSelectionModel}
          />
        </Box>
      ) : null}
      {errors.attendees && (
        <fieldset tabIndex={-1} name='attendees' className='error-holder'>
          <FormHelperText className='error-msg'>
            {errors.attendees}
          </FormHelperText>
        </fieldset>
      )}
    </SpeechCraftContainer>
  );
};

export default Recipients;
