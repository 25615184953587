import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  Typography,
  AccordionDetails,
  Stack,
  Avatar,
} from '@mui/material';
import dayjs from 'dayjs';

import { getUserName } from 'utils/utility';

import { AccordionWrapper, AccordionHeader } from 'components/Accordion/style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dummyImage from 'assets/images/dummyImage.png';

import { useFormikContext } from 'formik';

import { InfoSection } from '../style';

const Summary: FunctionComponent<any> = () => {
  const { values } = useFormikContext<any>();

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { speech, speechDate, attendees, groupComment } = values;
  const [speechTitle, description] = speech?.name?.split('#') || [];

  return (
    <Box>
      <Typography variant='body1' mb={4}>
        {description}
      </Typography>

      <InfoSection>
        <Typography variant='h3' mb={7.5}>
          General Information
        </Typography>
        <Typography variant='body1'>Completed Speech</Typography>
        <Typography variant='h4' mb={5}>
          {speechTitle}
        </Typography>
        <Typography variant='body1'>Date</Typography>
        <Typography variant='h4'>
          {dayjs(speechDate)?.format('MMMM DD, YYYY')}
        </Typography>
      </InfoSection>

      <InfoSection>
        <Typography variant='h3' mb={7.5}>
          Speechcrafters
        </Typography>
        <Typography variant='body1'>Validating Speech for</Typography>
        <Typography variant='h4' mb={7.5}>
          {attendees.length}
        </Typography>

        {[
          {
            title: 'Selected Speechcrafters',
            attendies: attendees,
          },
          {
            title: 'Comments',
            comments: { groupComment, attendees },
          },
        ].map(({ title, attendies, comments }: any, index: any) => {
          const panel = `panel${index + 1}`;
          return (
            <AccordionWrapper
              className='theme-accordion'
              key={index}
              expanded={expanded === panel}
              onChange={handleAccordianChange(panel)}
            >
              <AccordionHeader
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}bh-content`}
                id={`panel${index + 1}bh-header`}
              >
                <Typography variant='h4'>{title}</Typography>
              </AccordionHeader>
              <AccordionDetails>
                {attendies && (
                  <Stack gap={4}>
                    {attendies.map((user: any) => (
                      <Stack direction='row' alignItems='center' gap={2.5}>
                        <Avatar
                          alt='User'
                          src={user?.memberPhotoUrl ? user?.memberPhotoUrl : user?.profileImage?.small || dummyImage}
                          sx={{ width: '26px', height: '26px' }}
                        />
                        <Typography variant='h5'>
                          {getUserName(user)}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
                {comments && (
                  <Stack gap={7.5}>
                    {comments?.groupComment && (
                      <Stack gap={1}>
                        <Typography variant='h5'>Common Feedback</Typography>
                        <Typography variant='h5'>
                          {comments?.groupComment}
                        </Typography>
                      </Stack>
                    )}
                    {comments.attendees?.map((user: any) =>
                      user?.individualComment ? (
                        <Stack gap={1}>
                          <Typography variant='h5'>
                            {`Individual Feedback: `}
                            <Typography
                              variant='h5'
                              component='span'
                              className='color-primary'
                            >
                              {getUserName(user)}
                            </Typography>
                          </Typography>
                          <Typography variant='h5'>
                            {user?.individualComment}
                          </Typography>
                        </Stack>
                      ) : null,
                    )}
                  </Stack>
                )}
              </AccordionDetails>
            </AccordionWrapper>
          );
        })}
      </InfoSection>
    </Box>
  );
};

export default Summary;
