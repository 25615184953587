import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Box, Stack, Typography, Grid, SelectChangeEvent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { isEmpty, get, map, size } from 'lodash';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import Button from 'elements/Button';
import Loader from 'elements/Loader';
import DropdownField from 'elements/DropdownField';
import dummyImage from 'assets/images/dummyImage.png';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';

import { getUserName } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';
import { useGetMemberOverviewQuery } from 'api/bcmDashboardApi';

import EmptyStateMessage from './EmptyStateMessage';
import {
  Container,
  StickyDataGrid,
  StyledAvatar,
  StyledCell,
} from 'pages/BcmDashboard/components/style';
import { scrollCellIntoView } from 'utils/utility';

const columns: GridColDef[] = [
  {
    field: 'user',
    headerName: 'Name',
    width: 250,
    sortable: true,
    renderCell: (params: any) => {
      return (
        <StyledCell gap={4}>
          <StyledAvatar
            src={params.row.user.image || dummyImage}
            alt={params.row.user.name}
          />
          <Typography variant='body1'>{params.row.user.name}</Typography>
        </StyledCell>
      );
    },
    renderHeader: () => <Typography variant='h4'>Name</Typography>,
    valueGetter: params => params.row?.user?.name,
  },
  {
    field: 'currentCredential',
    headerName: 'Current Credential',
    width: 200,
    renderHeader: () => (
      <Typography variant='h4'>Current Credential</Typography>
    ),
    valueFormatter: params => params.value || '--',
  },
  {
    field: 'highestAchievement',
    width: 200,
    renderHeader: () => (
      <Typography variant='h4'>Highest Achievement</Typography>
    ),
  },
  {
    field: 'completedPaths',
    headerName: 'Completed Paths',
    renderHeader: () => <Typography variant='h4'>Completed Paths</Typography>,
    width: 200,
    renderCell: (params: any) => (
      <Stack gap={1}>
        {params.row.completedPaths.length
          ? params.row.completedPaths.map(
              (completedPath: string, index: number) => (
                <Typography key={index}>{completedPath}</Typography>
              ),
            )
          : ''}
      </Stack>
    ),
    flex: 1,
    valueGetter: params => size(params.row.completedPaths),
  },
  {
    field: 'lastSpeech',
    width: 200,
    renderHeader: () => <Typography variant='h4'>Last Speech</Typography>,
    valueFormatter: params => {
      if (!params.value) {
        return '--';
      }
      return dayjs(params.value)?.format('MMMM DD, YYYY');
    },
  },
];

export function SortedDescendingIcon() {
  return (
    <>
      <ArrowDropDownIcon className='icon' />
    </>
  );
}

export function SortedAscendingIcon() {
  return (
    <>
      <ArrowDropUpIcon className='icon' />
    </>
  );
}

export function UnsortedIcon() {
  return (
    <div className='unsorted-icon'>
      <ArrowDropUpIcon className='icon' />
      <ArrowDropDownIcon className='icon' />
    </div>
  );
}

const MemberOverview: FunctionComponent<any> = () => {
  const [selectedClub, setSelectedClub] = useState('');
  const [sortModel, setSortModel] = useState<any>([{}]);

  const { roles: userRoles = [] } = useAppContext();

  const {
    data,
    isFetching: isFetchingMembers,
    isSuccess,
    isError,
  } = useGetMemberOverviewQuery(
    { clubId: selectedClub },
    { skip: !selectedClub },
  );

  const clubOptions = useMemo(
    () =>
      userRoles.reduce((acc: any, value: any) => {
        const { name, uuid, roles = [] } = value;

        const hasBcmRole = roles.some(({ isBcm }: any) => isBcm);

        if (!hasBcmRole) {
          return acc;
        }

        return [...acc, { label: name, value: uuid }];
      }, []),
    [userRoles],
  );

  const rows = useMemo(() => {
    return map(get(data, 'results', []), (value, index) => {
      const {
        user,
        highestAchievement,
        completedPaths = [],
        lastSpeech,
        currentCredential,
      } = value;
      return {
        id: index,
        user: {
          name: getUserName(user),
          image: user?.memberPhotoUrl || user?.profileImage?.medium,
        },
        currentCredential,
        highestAchievement: highestAchievement,
        completedPaths: completedPaths || [],
        lastSpeech: lastSpeech ?? '',
      };
    });
  }, [data]);

  const onSortModelChange = useCallback(
    (newSortModel: any) => {
      const { field, sort } = sortModel[0] || {};
      if (isEmpty(newSortModel) && sort === 'desc') {
        setSortModel([{ field, sort: 'asc' }]);

        return;
      }

      setSortModel(newSortModel);
    },
    [sortModel],
  );

  useEffect(() => {
    if (clubOptions.length === 1) {
      setSelectedClub(clubOptions[0].value);
    }
  }, [clubOptions]);

  return (
    <Box mb={10}>
      <Typography variant='h2'>Member Overview</Typography>
      <Grid
        container
        mt={2}
        gap={3}
        alignItems='flex-end'
        justifyContent='space-between'
      >
        {size(clubOptions) > 1 && (
          <Grid item xs={12} sm={5}>
            <FormControlLabel
              label='Club'
              labelPlacement='top'
              control={
                <DropdownField
                  value={selectedClub}
                  options={clubOptions}
                  handleChange={(event: SelectChangeEvent) =>
                    setSelectedClub(event.target.value)
                  }
                  placeholder='Select a Club'
                />
              }
            />
          </Grid>
        )}

        {/* <Grid item xs={12} sm={5} className='text-end'>
          <Button onClick={() => {}} color='ternary'>
            <FileDownloadOutlinedIcon className='mr-5' />
            Export Excel/CSV
          </Button>
        </Grid> */}
      </Grid>
      <Container>
        {isFetchingMembers || (isEmpty(data?.results) && isFetchingMembers) ? (
          <Loader />
        ) : isSuccess && !isEmpty(data) && selectedClub ? (
          <StickyDataGrid
            rowHeight={76}
            autoHeight
            getRowHeight={() => 'auto'}
            hideFooter={true}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnUnsortedIcon: UnsortedIcon,
            }}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            slotProps={{
              cell: {
                onFocus: (event: any) =>
                  scrollCellIntoView(event.currentTarget),
              },
            }}
          />
        ) : (
          <EmptyStateMessage
            selectedClub={selectedClub}
            isSuccess={isSuccess}
            data={data?.results}
            isError={isError}
          />
        )}
      </Container>
    </Box>
  );
};

export default MemberOverview;
