import React, { FC, useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useHistory,
  useParams,
  Link,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import dayjs, { Dayjs } from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import parse, { domToReact } from 'html-react-parser';
import { isEmpty, size } from 'lodash';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, List, ListItem, Typography, Stack } from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';

import Button from 'elements/Button';
import Loader from 'elements/Loader';
import DatePicker from 'elements/DatePicker';
import TextField from 'elements/Form/TextField';
import SectionHeader from 'components/SectionHeader';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import Confirmation from 'components/Confirmation';
import DropdownField from 'elements/DropdownField';

// import ToastmastersIcon from 'assets/images/toastmasters.svg';
// import PathwaysIcon from 'assets/images/pathways.svg';
// import SpeechcraftIcon from 'assets/images/speechcraft-logo.svg';
import {
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
} from 'config';

import {
  useGetEvaluationFormQuery,
  useGetSubmittedEvaluationFormQuery,
  useGetEvaluationFormRequestQuery,
  usePostEvaluationFormMutation,
  usePostEvaluationFeedbackFormMutation,
} from 'api/evaluationFeedbackApi';
import { useAppContext } from 'contexts/AppContext';
import { useIsSpeechCraftMFE } from 'hooks';
import { useGetClubMembersQuery } from 'pages/Dashboard/components/Feedback/feedbackApi';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';

import Questions from './Questions';
import EvaluationCriteria from './EvaluationCriteria';
import { getValidatioinSchema, getInitialFormValues } from './constants';
import {
  Container,
  NavBreadCrumbs,
  FormWrapperOuter,
  FormWrapper,
  PrintIconHolder,
  BrandLogos,
} from './style';
import { getPlatformName, getUserName, parseDate } from 'utils/utility';
import { HOC } from 'elements/Modal';
import classNames from 'classnames';
import ExpiredRequest from 'components/ExpiredRequest';
import useScrollToError from 'hooks/useScrolltoError';

const renderBackButton = (
  <div className='back-btn'>
    <ArrowBackIosIcon />
    Back
  </div>
);

const ConfirmModal = HOC(Confirmation);
const ExpiredRequestModal = HOC(ExpiredRequest);

const EvaluationDigitalForm: FC<any> = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isExpiredRequestModalOpen, setIsExpiredRequestModalOpen] = useState(false);

  const history = useHistory();
  const { id } = useParams<any>();
  const componentRef = useRef<any>();
  const { search, state: routerState = {} } = useLocation<any>();
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const user = getAuthenticatedUser();

  const params = new URLSearchParams(search);
  const formType: any = params.get('type');
  const isSubmittedForm = formType === 'submitted';
  const isRequestedForm = formType === 'requested';
  const isFeedbackForm = formType === 'feedback';
  const isEmptyForm = formType !== 'submitted' && formType !== 'requested';
  const isReadonlyMode =
    isSubmittedForm ||
    (isFeedbackForm && !isEmpty(routerState) ? false : isEmptyForm);

  const { isLoading: isFormLoading, data: evaluationForm = {} } =
    useGetEvaluationFormQuery(id, { skip: !id || !isEmptyForm });

  const {
    isLoading: isSubmittedFormLoading,
    data: submittedEvaluationForm = {},
  } = useGetSubmittedEvaluationFormQuery(id, { skip: !id || !isSubmittedForm });

  const { isLoading: isFormRequestLoading, data: requestedFormRequest = {}, error }: any =
    useGetEvaluationFormRequestQuery(id, { skip: !id || !isRequestedForm });

  const { data: clubMembers = [], isFetching: isFetchingMembers } =
    useGetClubMembersQuery(
      { clubId: routerState?.club?.id },
      { skip: !routerState?.club?.id || !isFeedbackForm },
    );

  const { data: eventMembersData, isFetching: isFetchingEventMembers } =
    useGetEventAttendeesQuery(
      { eventId: event?.id, attendeesType: 'all' },
      { skip: !event?.id || !isSpeechCraftMFE || !isFeedbackForm },
    );

  const [submitEvaluationForm] = usePostEvaluationFormMutation();

  const [submitEvaluationFeedbackForm] =
    usePostEvaluationFeedbackFormMutation();

  const formData = isEmptyForm
    ? evaluationForm
    : isSubmittedForm
    ? submittedEvaluationForm
    : requestedFormRequest;

  const formTitle = isEmptyForm
    ? formData?.title
    : formData?.evaluationForm?.title;

  const initialFormValues: any = React.useMemo(
    () =>
      getInitialFormValues(formData, isEmptyForm, isFeedbackForm, routerState),
    [formData, isEmptyForm, isFeedbackForm, routerState],
  );

  const clubMembersOptions = (
    isSpeechCraftMFE ? eventMembersData?.attendees || [] : clubMembers
  ).reduce((acc: any, member: any) => {
    if (member.id === user.userId) {
      return acc;
    }

    return [
      ...acc,
      {
        label: getUserName(member),
        value: member.id,
      },
    ];
  }, []);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidatioinSchema(
      initialFormValues?.questions,
      isFeedbackForm,
    ),
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  });

  useEffect(() => {
    if(error?.data?.detail.includes("Not found") && size(requestedFormRequest) < 1) {
      setIsExpiredRequestModalOpen(true)
    }
  }, [error])

  useEffect(() => {
    const handleUnloadEvent = (event: any) => {
      if (!isFeedbackForm) {
        return;
      }

      // For IE and Firefox prior to version 4
      if (event) {
        event.returnValue = 'Sure?';
      }

      // For Safari
      return 'Sure?';
    };
    window.addEventListener('beforeunload', handleUnloadEvent);

    return () => window.removeEventListener('beforeunload', handleUnloadEvent);
  }, [isFeedbackForm]);

  const {
    setFieldValue,
    values: formikValues,
    dirty,
    setFieldTouched,
    touched,
    errors,
    isValid,
    isSubmitting,
  }: any = formik;
  useScrollToError(formik);

  const handleActionButton = () => {
    if (!dirty && !isFeedbackForm) {
      handleConfirmation();
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const handleConfirmation = () => {
    history.push(
      `${getPlatformName()}/${
        isRequestedForm ? 'requests' : 'feedback/evaluation-feedback'
      }`,
    );
  };

  const handleExpiredRequest = () => {
    history.push(`${getPlatformName()}`);
  };

  const handlePrint: any = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleUpdateField = (event: any) => {
    if (isReadonlyMode) {
      return;
    }

    const { name, value } = event.target;
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const handleUpdateDate = (newValue: Dayjs | null) => {
    setFieldValue('date', dayjs(newValue).format('YYYY-MM-DD'));
    setFieldTouched('date', true, false);
  };

  const handleUpdateQuestion = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldType: string,
  ) => {
    if (isReadonlyMode) {
      return;
    }

    const { name, value } = event.target;
    const updatedQuestions = {
      ...formikValues.questions,
      [name]: { ...formikValues.questions[name], [fieldType]: value },
    };
    setFieldValue('questions', updatedQuestions);
  };

  const getMemberName = () => {
    const data = isSpeechCraftMFE
      ? eventMembersData?.attendees || []
      : clubMembers;

    const member = data.find((m: any) => formikValues.member === m.id) || {};

    return getUserName(member);
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);

    const dataToUpdate = {
      request: id,
      excelled: values.excelled,
      workOn: values.workOn,
      challenge: values.challenge,
      submittedAt: values.date,
      answers: Object.values(values.questions).reduce(
        (acc: any, question: any) => ({
          ...acc,
          [question.id]: { answer: question.answer, comment: question.comment },
        }),
        {},
      ),
      ...(isFeedbackForm && {
        learner: values.member,
        speechTitle: values.speechTitle,
        speechDate: parseDate(values.date).toISOString(),
        evaluator: routerState?.evaluator?.userId,
        evaluation_form: id,
        club: routerState?.club?.uuid,
      }),
      ...(isSpeechCraftMFE && {
        event: event?.id,
      }),
    };

    try {
      if (isFeedbackForm) {
        await submitEvaluationFeedbackForm(dataToUpdate).unwrap();
        toast.success(
          `Evaluation has been successfully shared with ${getMemberName()}!`,
        );
        history.push(`${getPlatformName()}/feedback/evaluation-feedback`);
        return;
      }

      await submitEvaluationForm(dataToUpdate).unwrap();
      toast.success(
        `Evaluation has been successfully shared with ${values.member}!`,
      );
      history.push(`${getPlatformName()}/requests`);
    } catch (e: any) {
      toast.error(
        e?.data || 'Oops, an error occurred. Please try again later.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  const showSubmitButton =
    (isFeedbackForm && !isEmpty(routerState)) ||
    (formData?.isSubmitted !== undefined && !formData.isSubmitted);

  const isLoading =
    isFormLoading || isSubmittedFormLoading || isFormRequestLoading;

  const options = {
    replace: ({ name, attribs, children }: any) => {
      if (attribs && name === 'ul') {
        return (
          <List className='flex-start'>{domToReact(children, options)}</List>
        );
      }

      if (attribs && name === 'li') {
        return (
          <ListItem>
            <ArrowRightRoundedIcon />
            {domToReact(children, options)}
          </ListItem>
        );
      }
    },
  };

  if (!id || (isFeedbackForm && isEmpty(routerState))) {
    return (
      <Redirect to={`${getPlatformName()}/feedback/evaluation-feedback`} />
    );
  }

  return (
    <Container>
      <NavBreadCrumbs>
        <li>
          <Link to={`${getPlatformName()}/feedback`}>Feedback</Link>
        </li>
        <li>
          <span className='separator'>/</span>
        </li>
        <li>Digital Evaluation Forms</li>
      </NavBreadCrumbs>
      {isLoading ? (
        <Loader position='relative' />
      ) : (
        <>
          <SectionHeader
            title={formTitle}
            buttonText={renderBackButton}
            buttonColor='primary'
            handleActionButton={handleActionButton}
          />
          <FormWrapperOuter>
            <PrintIconHolder
              aria-label='Print'
              className='reset-btn'
              onClick={handlePrint}
            >
              <PrintRoundedIcon />
            </PrintIconHolder>

            <FormWrapper ref={componentRef}>
              <BrandLogos>
                <List>
                  <ListItem>
                    <img
                      src={process.env.REACT_APP_FAVICON_URL}
                      alt='Toastmasters'
                    />
                  </ListItem>
                  <ListItem className='platform-logo'>
                    <img
                      src={
                        isSpeechCraftMFE
                          ? REACT_APP_SPEECHCRAFT_LOGO_URL
                          : REACT_APP_PATHWAYS_LOGO_URL
                      }
                      alt={`${isSpeechCraftMFE ? 'Speechcraft' : 'Pathways'}`}
                    />
                  </ListItem>
                </List>
              </BrandLogos>
              <Typography variant='h1' className='fw-700' pb={2}>
                Evaluation Form
              </Typography>
              <Typography variant='h2' className='fw-500'>
                {formTitle}
              </Typography>
              <Box py={5}>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={8} className='inline-label-control'>
                    <FormControlLabel
                      label='Member Name'
                      labelPlacement='start'
                      control={
                        isFeedbackForm ? (
                          <DropdownField
                            value={String(formikValues.member)}
                            options={clubMembersOptions}
                            handleChange={handleUpdateField}
                            name='member'
                            className={`${
                              formikValues.member ? 'has-data' : ''
                            }`}
                            helperText={touched['member'] && errors['member']}
                            error={!!touched['member'] && !!errors['member']}
                            placeholder={`Select ${
                              isSpeechCraftMFE ? 'speechcrafter' : 'member'
                            }`}
                            isLoading={
                              isFetchingMembers || isFetchingEventMembers
                            }
                          />
                        ) : (
                          <TextField
                            fullWidth
                            value={formikValues.member}
                            name='member'
                            disabled
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      label='Date'
                      className='w-auto'
                      labelPlacement='start'
                      control={
                        <DatePicker
                          label='Date'
                          value={formikValues.date}
                          handleChange={handleUpdateDate}
                          name='date'
                          className={`primary-color-icon ${
                            formikValues.date ? 'has-data' : ''
                          }`}
                          helperText={touched['date'] && errors['date']}
                          error={!!touched['date'] && !!errors['date']}
                          disabled={isReadonlyMode || !isFeedbackForm}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8} className='inline-label-control'>
                    <FormControlLabel
                      label='Evaluator'
                      labelPlacement='start'
                      control={
                        <TextField
                          className={classNames({
                            'evaluator-field': formikValues.evaluator !== '',
                          })}
                          fullWidth
                          name='evaluator'
                          value={formikValues.evaluator}
                          disabled
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography mt={2}>
                      <Typography className='grey-text' component='strong'>
                        Speech Length
                      </Typography>{' '}
                      {formikValues.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className='inline-label-control'>
                    <FormControlLabel
                      label='Speech Title'
                      labelPlacement='start'
                      control={
                        <TextField
                          fullWidth
                          id='speech-title'
                          name='speechTitle'
                          value={formikValues.speechTitle}
                          onChange={handleUpdateField}
                          helperText={
                            touched['speechTitle'] && errors['speechTitle']
                          }
                          error={
                            !!touched['speechTitle'] && !!errors['speechTitle']
                          }
                          disabled={isReadonlyMode || !isFeedbackForm}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box py={5}>
                <Typography variant='h4'>Purpose Statement</Typography>
                {formikValues.purposeStatement &&
                  parse(formikValues.purposeStatement, options)}
                <Typography variant='h4'>Notes for the Evaluator</Typography>
                {formikValues.notes && parse(formikValues.notes, options)}
                {isRequestedForm && (
                  <Box>
                    <Typography variant='h4' pt={4} pb={2}>
                      Feedback Request from {formikValues.member}
                    </Typography>
                    <Typography>{formikValues.description}</Typography>
                  </Box>
                )}
                <Typography variant='h4' pt={4} pb={2}>
                  General Comments
                </Typography>
                <Box pb={4} className='page-break'>
                  <FormControlLabel
                    label='You excelled at:'
                    labelPlacement='top'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='excelled'
                        value={formikValues.excelled}
                        onChange={handleUpdateField}
                        helperText={touched['excelled'] && errors['excelled']}
                        error={!!touched['excelled'] && !!errors['excelled']}
                        disabled={isReadonlyMode}
                      />
                    }
                  />
                </Box>
                <Box pb={4} className='page-break'>
                  <FormControlLabel
                    label='You may want to work on:'
                    labelPlacement='top'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='workOn'
                        value={formikValues.workOn}
                        onChange={handleUpdateField}
                        helperText={touched['workOn'] && errors['workOn']}
                        error={!!touched['workOn'] && !!errors['workOn']}
                        disabled={isReadonlyMode}
                      />
                    }
                  />
                </Box>
                <Box className='page-break'>
                  <FormControlLabel
                    label='To challenge yourself:'
                    labelPlacement='top'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='challenge'
                        value={formikValues.challenge}
                        onChange={handleUpdateField}
                        helperText={touched['challenge'] && errors['challenge']}
                        error={!!touched['challenge'] && !!errors['challenge']}
                        disabled={isReadonlyMode}
                      />
                    }
                  />
                </Box>
                <Questions
                  questions={formikValues.questions}
                  handleUpdateQuestion={handleUpdateQuestion}
                  isReadonlyMode={isReadonlyMode}
                  errors={errors?.questions}
                  touched={touched?.questions}
                />
                <EvaluationCriteria questions={formikValues.questions} />
              </Box>
              {showSubmitButton && (
                <Stack justifyContent='flex-end' direction='row' gap={2}>
                  <Button
                    variant='outlined'
                    onClick={handleActionButton}
                    className='w-75'
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => formik.handleSubmit()}
                    disabled={isSubmitting}
                    className='w-75'
                  >
                    Share evaluation
                  </Button>
                </Stack>
              )}
              <Box pt={10}>
                <Typography variant='body2'>
                  © 2016 Toastmasters International. All rights reserved.
                  Toastmasters International, the Toastmasters International
                  logo and all other Toastmasters International trademarks and
                  copyrights are the sole property of Toastmasters International
                  and may be used only by permission. Item 8205E Rev. 12/2022
                </Typography>
              </Box>
            </FormWrapper>
          </FormWrapperOuter>
        </>
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          handleClose={() => setIsConfirmModalOpen(false)}
          title='Confirmation'
          buttonText='Continue'
          cancelText='Stay on page'
          showLoader={false}
          handleConfirmation={handleConfirmation}
        >
          If you continue, you will lose all text entered on this page.
        </ConfirmModal>
      )}


      {isExpiredRequestModalOpen && (
        <ExpiredRequestModal
          handleClose={() => {}}
          title='Expired Request'
          buttonText='Go to Learning Dashboard'
          showLoader={false}
          showCloseIcon={false}
          handleConfirmation={handleExpiredRequest}
        >
          This action is no longer available. Please contact <a href="mailto:educationprogram@toastmasters.org">educationprogram@toastmasters.org</a> if you have any questions.
        </ExpiredRequestModal>
      )}
    </Container>
  );
};

export default EvaluationDigitalForm;
