import { styled, Box } from '@mui/material';
import { DeviceUp } from 'styles/breakpoints';

export const ConfirmationBox = styled(Box)`
    position: relative;

    p {
      a {
        color: ${({ theme }) => theme.palette.ternary.tertiary1};
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
`;

export const CTAButtons = styled(Box)`
    margin-top: 40px;
    text-align: center;

    & .MuiButton-root {
        padding: 7px 20px;
    }
`;
